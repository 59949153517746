.html, body {
  background-color: #000000;
  color: #050505;
  font-family: 'Roboto', sans-serif;
  font-size: calc(10px + 1vmin);
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: auto;
}

.App {
  text-align: center;
  color: rgb(255, 255, 255);
}

.App-logo {
  height:15vmax;
  object-fit:cover;
  background-size:cover;
  background-position:center;
  border-radius: 55% 55% 40% 40%;
  border: 2px solid black;
}

.Content {
  text-align: center;
  align-items: center;
  max-width: 85vw;
}

.NavBar {
  padding: 2px;
  color: #ffffff;
  background: linear-gradient(0.35turn,#1B77F8, #34B4C1, #4CF087);
  height: 10%;
  text-align: right;
  padding-right: 10px;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px

}

.NavItem {
  color: #000000;
  text-decoration: none;
  font-size: calc(8px + 1vmin);
  padding: 10px;
}

.App-header {
  background-color: #000000;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 3vmin);
  color: #ffffff;
}

.App-link {
  color: #1B77F8;
}
.MJ-link {
  color: white;
}

.Button {
  margin: 10px;
  background-color: #185801;
  color: #ffffff;
  border-radius: 25px;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border: 2px solid #000000;
  box-shadow: 0 1px #999;
  transition-duration: 0.4s;
  font-weight: bold;
}

.Button:hover {
  background-color: green; 
  color: white;
}

.Button:active {
  background-color: green;
  box-shadow: 0 1px #666;
  transform: translateY(4px);
}

.Box {
  padding: 1.5rem 1rem 1.5rem 1rem;
  border-color: #182523;
  background-color: #171923;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 5rem auto;
  max-width: 65vw;
  min-height: 15vh;
  font-size: calc(10px + 1vmin);
  color: #CBD5E0;
}

.blue {
  color: #1B77F8;
}
.highlight-blue {
  border-radius: 1.5em 0 1.5em 0;
  background-image: linear-gradient(
    -270deg,
    rgba(20, 0, 255, 0.15),
    #1B77F8 92%,
    rgba(0, 255, 20, 0.05)
  );
}

.highlight {
  font-size: calc(10px + 1vmin);
  color: #CBD5E0;
}

.highlight-green {
  border-radius: 1.5em 0 1.5em 0;
  background-image: linear-gradient(
    -270deg,
    rgba(20, 0, 255, 0.15),
    rgba(76,240,135, 0.75) 92%,
    rgba(0, 255, 20, 0.05)
  );
}

.highlight-greenblue {
  border-radius: 1.5em 0 1.5em 0;
  background-image: linear-gradient(
    -270deg,
    rgba(27,119,248, 0.15),
    #34B4C1 80%,
    rgba(76,240,135, 0.05)
  );
}

.highlight-red {
  border-radius: 1.5em 0 1.5em 0;
  background-image: linear-gradient(
    -270deg,
    rgba(248, 27, 27, 0.15),
    rgb(200, 2, 2,0.75) 92%,
    rgba(240, 76, 76, 0.05)
  );
}

.table-outline {
  border: 1px solid #CBD5E0;
  border-radius: 0.5em;
  padding: 0.1rem;
  margin: 0.1rem;
  background-color: #171923;
}
