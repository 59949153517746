.description-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: calc(10px + 1.5vmin);
    padding: 2rem 0 0 0;
    margin: 0 0rem 0 0rem;
    color: white;
    font-weight: bold;
  }
  .description-text-small {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: calc(10px + 0.5vmin);
    color: white;
    font-weight: bold;
  }

  .Portal-box {
    padding: 1.5rem 1rem 1.5rem 1rem;
    border-color: #182523;
    background-color: #171923;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    align-items: left;
    margin: 2rem auto;
    max-width: 65vw;
    height: 600px;
    min-height: 55vh;
    font-size: calc(10px + 1vmin);
    color: #CBD5E0;
    overflow: auto;
    
  }
  
  .Portal-text {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
    font-size: calc(10px + 0.75vmin);
    padding: 0.5rem;
    margin: 0 0.75rem 0 0.75rem;
    max-width: 35vw;
    color: white;
    font-weight: bold;
  }
  
  .form-input {
    margin: 0.5rem 0 0.5rem 0;
    border: 1px solid #171923;
    border-radius: 8px;
    padding: 0.25rem;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);
  }

  .info-box {
    content: attr(title);
    display: flex;
    align-items: right;
    position: absolute;
    flex-wrap: wrap;
    background-color: rgb(225, 225, 225);
    color: rgb(0, 0, 0);
    border-radius: 15%;
    border: 1px solid green;
    max-width: 15vw;
    font-size: 10px;
    font-weight: bold;
    padding: 10px;
  }

  .table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: calc(10px + 0.75vmin);
    padding: 0.25px;
    max-width: 25vw;
    color: white;
    font-weight: bold;
  }
  .tableText {
    color: rgb(225, 225, 225);
    font-weight: bold;
    align-items: center;
    margin: auto;
  }

  .fa {
    margin-left: 4px;
  }
 
  
  @media (prefers-reduced-motion: no-preference) {
    .Portal-logo {
      animation: 
      App-logo-spin infinite 3.5s;
      
    }
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }