body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.html, body {
  background-color: #000000;
  color: #050505;
  font-family: 'Roboto', sans-serif;
  font-size: calc(10px + 1vmin);
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: auto;
}

.App {
  text-align: center;
  color: rgb(255, 255, 255);
}

.App-logo {
  height:15vmax;
  object-fit:cover;
  background-size:cover;
  background-position:center;
  border-radius: 55% 55% 40% 40%;
  border: 2px solid black;
}

.Content {
  text-align: center;
  align-items: center;
  max-width: 85vw;
}

.NavBar {
  padding: 2px;
  color: #ffffff;
  background: linear-gradient(0.35turn,#1B77F8, #34B4C1, #4CF087);
  height: 10%;
  text-align: right;
  padding-right: 10px;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px

}

.NavItem {
  color: #000000;
  text-decoration: none;
  font-size: calc(8px + 1vmin);
  padding: 10px;
}

.App-header {
  background-color: #000000;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 3vmin);
  color: #ffffff;
}

.App-link {
  color: #1B77F8;
}
.MJ-link {
  color: white;
}

.Button {
  margin: 10px;
  background-color: #185801;
  color: #ffffff;
  border-radius: 25px;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border: 2px solid #000000;
  box-shadow: 0 1px #999;
  transition-duration: 0.4s;
  font-weight: bold;
}

.Button:hover {
  background-color: green; 
  color: white;
}

.Button:active {
  background-color: green;
  box-shadow: 0 1px #666;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.Box {
  padding: 1.5rem 1rem 1.5rem 1rem;
  border-color: #182523;
  background-color: #171923;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 5rem auto;
  max-width: 65vw;
  min-height: 15vh;
  font-size: calc(10px + 1vmin);
  color: #CBD5E0;
}

.blue {
  color: #1B77F8;
}
.highlight-blue {
  border-radius: 1.5em 0 1.5em 0;
  background-image: linear-gradient(
    -270deg,
    rgba(20, 0, 255, 0.15),
    #1B77F8 92%,
    rgba(0, 255, 20, 0.05)
  );
}

.highlight {
  font-size: calc(10px + 1vmin);
  color: #CBD5E0;
}

.highlight-green {
  border-radius: 1.5em 0 1.5em 0;
  background-image: linear-gradient(
    -270deg,
    rgba(20, 0, 255, 0.15),
    rgba(76,240,135, 0.75) 92%,
    rgba(0, 255, 20, 0.05)
  );
}

.highlight-greenblue {
  border-radius: 1.5em 0 1.5em 0;
  background-image: linear-gradient(
    -270deg,
    rgba(27,119,248, 0.15),
    #34B4C1 80%,
    rgba(76,240,135, 0.05)
  );
}

.highlight-red {
  border-radius: 1.5em 0 1.5em 0;
  background-image: linear-gradient(
    -270deg,
    rgba(248, 27, 27, 0.15),
    rgb(200, 2, 2,0.75) 92%,
    rgba(240, 76, 76, 0.05)
  );
}

.table-outline {
  border: 1px solid #CBD5E0;
  border-radius: 0.5em;
  padding: 0.1rem;
  margin: 0.1rem;
  background-color: #171923;
}

.description-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: calc(10px + 1.5vmin);
    padding: 2rem 0 0 0;
    margin: 0 0rem 0 0rem;
    color: white;
    font-weight: bold;
  }
  .description-text-small {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: calc(10px + 0.5vmin);
    color: white;
    font-weight: bold;
  }

  .Portal-box {
    padding: 1.5rem 1rem 1.5rem 1rem;
    border-color: #182523;
    background-color: #171923;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    align-items: left;
    margin: 2rem auto;
    max-width: 65vw;
    height: 600px;
    min-height: 55vh;
    font-size: calc(10px + 1vmin);
    color: #CBD5E0;
    overflow: auto;
    
  }
  
  .Portal-text {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
    font-size: calc(10px + 0.75vmin);
    padding: 0.5rem;
    margin: 0 0.75rem 0 0.75rem;
    max-width: 35vw;
    color: white;
    font-weight: bold;
  }
  
  .form-input {
    margin: 0.5rem 0 0.5rem 0;
    border: 1px solid #171923;
    border-radius: 8px;
    padding: 0.25rem;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);
  }

  .info-box {
    content: attr(title);
    display: flex;
    align-items: right;
    position: absolute;
    flex-wrap: wrap;
    background-color: rgb(225, 225, 225);
    color: rgb(0, 0, 0);
    border-radius: 15%;
    border: 1px solid green;
    max-width: 15vw;
    font-size: 10px;
    font-weight: bold;
    padding: 10px;
  }

  .table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: calc(10px + 0.75vmin);
    padding: 0.25px;
    max-width: 25vw;
    color: white;
    font-weight: bold;
  }
  .tableText {
    color: rgb(225, 225, 225);
    font-weight: bold;
    align-items: center;
    margin: auto;
  }

  .fa {
    margin-left: 4px;
  }
 
  
  @media (prefers-reduced-motion: no-preference) {
    .Portal-logo {
      -webkit-animation: 
      App-logo-spin infinite 3.5s;
              animation: 
      App-logo-spin infinite 3.5s;
      
    }
  }

  @-webkit-keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
